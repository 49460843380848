import {
  AddressTypeLiteral,
  OrderFormData,
  ValidationResultOrderForm,
} from '@/types/OrderFormTypes';
import {
  AddressCorrectionOptions,
  AddressSuggestion,
  AddressValidationCheckResult,
  addressValidationResponseFromAPI,
  AddressValidationResult,
  formAddress,
  FormAddresses,
  FormBusinessAddress,
  FormData,
  FormDeviatingAddress,
  InternalSingleAddressValidationResult,
  SelectOption,
} from '@/models/OrderData';
import { computed, ComputedRef, reactive, Ref, ref } from 'vue';
import store from '@/store';
import { PaymentMethods } from '@/types/PaymentTypes';
import OrderService from '@/services/order-service';
import {
  AddressViolations,
  ValidationResultValue,
  ViolationFields,
} from '@/validators/validatorTypes';
import { scrollTo } from '@/composables/page-functions';
import FormService from '@/services/form-service';
import router from '@/router';
import { createDummyAddress } from '@/composables/general-functions';
import GtmService from '@/services/gtm-service';
import commonTexts from '@/data/commonTexts';

export const addressFields = [
  ViolationFields.POST_OFFICE_BOX_CITY,
  ViolationFields.POST_OFFICE_BOX_ZIP_CODE,
  ViolationFields.POST_OFFICE_BOX,
  ViolationFields.CITY,
  ViolationFields.STREET,
  ViolationFields.STREET_NUMBER,
  ViolationFields.ZIP_CODE,
  ViolationFields.COUNTRY,
];

export const personalDataFields = [
  ViolationFields.FIRST_NAME,
  ViolationFields.LAST_NAME,
  ViolationFields.SALUTATION,
];
export const optionalFields = [
  ViolationFields.EMAIL,
  ViolationFields.PHONE_NUMBER,
];

export function getDefaultCountry(
  countryOptions: Ref<{ text: string; value: string }[]>,
): string {
  if (
    countryOptions.value.some(
      (countryOption: { text: string; value: string }) =>
        countryOption.value === 'DE',
    )
  ) {
    return 'DE';
  } else {
    return countryOptions.value[0].value;
  }
}

export function getEmptyAddressValidationResult(): AddressValidationResult {
  const buildEmptyResult = function () {
    return {
      streetCorrected: false,
      zipCodeCorrected: false,
      cityCorrected: false,
      postOfficeBoxCorrected: false,
      postOfficeBoxCityCorrected: false,
      postOfficeBoxZipCodeCorrected: false,
      postOfficeBoxError: false,
      cityError: false,
      streetError: false,
      streetNumberError: false,
      zipCodeError: false,
      hasErrors: false,
      corrected: false,
      addressCorrectionOptions: [],
      cityCorrectionOptions: [],
      streetCorrectionOptions: [],
    };
  };

  return {
    privateAddress: buildEmptyResult(),
    businessAddress: buildEmptyResult(),
    deliveryAddress: buildEmptyResult(),
    invoiceAddress: buildEmptyResult(),
  };
}

const addressCorrected = function (
  addressValidationCheckResult: AddressValidationCheckResult,
) {
  if (addressValidationCheckResult.corrected) {
    // district correction is to be ignored always!
    return (
      addressValidationCheckResult.cityCorrected ||
      addressValidationCheckResult.streetCorrected ||
      addressValidationCheckResult.zipCodeCorrected ||
      addressValidationCheckResult.postOfficeBoxZipCodeCorrected ||
      addressValidationCheckResult.postOfficeBoxCityCorrected ||
      addressValidationCheckResult.postOfficeBoxCorrected
    );
  }
  return false;
};

export function showErrorOrWarningToUserAfterAddressValidation(
  addressCheckResult: addressValidationResponseFromAPI,
) {
  return (
    addressCheckResult.privateAddressCheckResult.hasErrors ||
    addressCorrected(addressCheckResult.privateAddressCheckResult) ||
    addressCheckResult.businessAddressCheckResult.hasErrors ||
    addressCorrected(addressCheckResult.businessAddressCheckResult) ||
    addressCheckResult.invoiceAddressCheckResult.hasErrors ||
    addressCorrected(addressCheckResult.invoiceAddressCheckResult) ||
    addressCheckResult.deliveryAddressCheckResult.hasErrors ||
    addressCorrected(addressCheckResult.deliveryAddressCheckResult)
  );
}

export function getAddressCorrectionOptionsForAddress(
  addressCheckResult: AddressValidationCheckResult,
): AddressCorrectionOptions {
  const addressCorrectionOptions = [];
  const cityCorrectionOptions = [];
  const streetCorrectionOptions = [];

  // convert response to array of objects
  if (addressCheckResult.adrKorOptions) {
    for (const [key, value] of Object.entries(
      addressCheckResult.adrKorOptions,
    )) {
      const id: number = parseInt(key);
      const { city, street, zipCode } = value;

      addressCorrectionOptions.push({
        id,
        city,
        street,
        zipCode,
      });
    }
  }
  // convert response to array of objects
  if (addressCheckResult.plzOrtKorOptions) {
    for (const [key, value] of Object.entries(
      addressCheckResult.plzOrtKorOptions,
    )) {
      cityCorrectionOptions.push({
        value: parseInt(key),
        text: value,
      });
    }
  }
  if (addressCheckResult.strKorOptions) {
    for (const [key, value] of Object.entries(
      addressCheckResult.strKorOptions,
    )) {
      streetCorrectionOptions.push({
        value: parseInt(key),
        text: value,
      });
    }
  }
  return {
    addressCorrectionOptions,
    cityCorrectionOptions,
    streetCorrectionOptions,
  };
}

export function setAddressesCorrectionOptions(
  addressCheckResult: addressValidationResponseFromAPI,
  addressValidationResult: Ref<AddressValidationResult>,
) {
  if (addressCheckResult.privateAddressCheckResult.address !== null) {
    const privateCorrOptions = getAddressCorrectionOptionsForAddress(
      addressCheckResult.privateAddressCheckResult,
    );
    addressValidationResult.value.privateAddress.addressCorrectionOptions =
      privateCorrOptions.addressCorrectionOptions;
    addressValidationResult.value.privateAddress.streetCorrectionOptions =
      privateCorrOptions.streetCorrectionOptions;
    addressValidationResult.value.privateAddress.cityCorrectionOptions =
      privateCorrOptions.cityCorrectionOptions;
  }

  if (addressCheckResult.businessAddressCheckResult.address !== null) {
    const businessCorrOptions = getAddressCorrectionOptionsForAddress(
      addressCheckResult.businessAddressCheckResult,
    );
    addressValidationResult.value.businessAddress.addressCorrectionOptions =
      businessCorrOptions.addressCorrectionOptions;
    addressValidationResult.value.businessAddress.streetCorrectionOptions =
      businessCorrOptions.streetCorrectionOptions;
    addressValidationResult.value.businessAddress.cityCorrectionOptions =
      businessCorrOptions.cityCorrectionOptions;
  }

  if (addressCheckResult.deliveryAddressCheckResult.address !== null) {
    const deliveryCorrOptions = getAddressCorrectionOptionsForAddress(
      addressCheckResult.deliveryAddressCheckResult,
    );
    addressValidationResult.value.deliveryAddress.addressCorrectionOptions =
      deliveryCorrOptions.addressCorrectionOptions;
    addressValidationResult.value.deliveryAddress.streetCorrectionOptions =
      deliveryCorrOptions.streetCorrectionOptions;
    addressValidationResult.value.deliveryAddress.cityCorrectionOptions =
      deliveryCorrOptions.cityCorrectionOptions;
  }

  if (addressCheckResult.invoiceAddressCheckResult.address !== null) {
    const invoiceCorrOptions = getAddressCorrectionOptionsForAddress(
      addressCheckResult.invoiceAddressCheckResult,
    );
    addressValidationResult.value.invoiceAddress.addressCorrectionOptions =
      invoiceCorrOptions.addressCorrectionOptions;
    addressValidationResult.value.invoiceAddress.streetCorrectionOptions =
      invoiceCorrOptions.streetCorrectionOptions;
    addressValidationResult.value.invoiceAddress.cityCorrectionOptions =
      invoiceCorrOptions.cityCorrectionOptions;
  }
}

export function checkAddressChangedByValidation(
  addressValidationCheckResult: InternalSingleAddressValidationResult,
) {
  return (
    addressValidationCheckResult.streetCorrected ||
    addressValidationCheckResult.cityCorrected ||
    addressValidationCheckResult.zipCodeCorrected ||
    addressValidationCheckResult.postOfficeBoxCorrected ||
    addressValidationCheckResult.postOfficeBoxCityCorrected ||
    addressValidationCheckResult.postOfficeBoxZipCodeCorrected
  );
}

export function normalizeCommonAddressData(
  address: formAddress | FormBusinessAddress | FormDeviatingAddress,
) {
  if (address.addressType === AddressTypeLiteral.STREET_ADDRESS) {
    //TODO: strings must be empty for API (also: API should be moved to connector)
    address.postOfficeBox = '';
    address.postOfficeBoxZipCode = '';
    address.postOfficeBoxCity = '';
  } else {
    address.street = '';
    address.streetNumber = '';
    address.city = '';
    address.zipCode = '';
  }
}

export function normalizePrivateAddressData(address: formAddress) {
  address.title = address.title === '00' ? '' : address.title;
  normalizeCommonAddressData(address);
  //set region to empty string if Print or if not configured for tenant
  // if (!showRegionSelect) {
  //   address.district = '';
  // }
  if (address.addressType === AddressTypeLiteral.POST_OFFICE_BOX) {
    address.addressAddition = '';
    address.room = '';
    address.additionalInfo = '';
    address.floor = '';
  }
}

export function normalizeBusinessAndDeviatingAddressData(
  address: FormBusinessAddress | FormDeviatingAddress,
) {
  normalizeCommonAddressData(address);
  if (address.addressType === AddressTypeLiteral.POST_OFFICE_BOX) {
    address.mailDeliverySelected = false;
    address.additionalInfo = '';
    address.floor = '';
    address.majorCustomerCity = '';
    address.majorCustomerZipCode = '';
  }
}

export function getEmptyPrivateAddress() {
  return {
    // Personal Data
    firstName: '',
    lastName: '',
    salutation: '00',
    title: '00',
    // Additional personal data
    email: '',
    phone: '',
    businessPhone: '',
    mobilePhone: '',
    addressNumber: '',
    //basic addressData
    country: 'DE',
    district: '',
    addressType: AddressTypeLiteral.STREET_ADDRESS,
    //streetAddressData
    street: '',
    streetNumber: '',
    floor: '',
    room: '',
    additionalInfo: '',
    addressAddition: '',
    zipCode: '',
    city: '',
    // postOfficeBox Address
    postOfficeBox: '',
    postOfficeBoxZipCode: '',
    postOfficeBoxCity: '',
    // Flags:
    nameEditable: true,
    businessAddress: false,
  };
}

export function prefillPrivateAddress(
  address: OrderFormData | null,
  countryOptions: Ref<{ text: string; value: string }[]>,
  loginEmail: string,
  prefillEmail: boolean,
) {
  if (null === address) {
    address = getEmptyPrivateAddress();
  }

  if (!address.country) {
    address.country = getDefaultCountry(countryOptions);
  }

  const prefillAddress = countryOptions.value.some(
    (countryOption: { text: string; value: string }) =>
      countryOption.value === address?.country,
  );

  if (address.salutation === '') {
    address.salutation = '00';
  }
  if (!address.district) {
    // address.district = 'EG';
    address.district =
      store.getters.formData.regionSelectOptions &&
      store.getters.formData.regionSelectOptions['-']
        ? '-'
        : 'EG';
  }

  if (!address.title) {
    address.title = '00';
  }

  if (!prefillAddress) {
    address.country = '';
    address.street = '';
    address.streetNumber = '';
    address.postOfficeBox = '';
    address.postOfficeBoxZipCode = '';
    address.postOfficeBoxCity = '';
    address.zipCode = '';
    address.city = '';
    address.additionalInfo = '';
    address.floor = '';
    address.room = '';
    address.addressAddition = '';
  }

  if (prefillEmail) {
    // Prefilling email form field:
    // 1. Do nothing, if address.email is defined and not empty
    // 2. If not, check login e-mail
    // 3. Else: set to default (empty string)
    if (!address.email && loginEmail) {
      address.email = loginEmail;
    } else if (!address.email) {
      address.email = '';
    }
  }

  address.addressType = address.postOfficeBox
    ? AddressTypeLiteral.POST_OFFICE_BOX
    : AddressTypeLiteral.STREET_ADDRESS;

  return address;
}

export function checkViolationsForErrorTypes(
  violations: ViolationFields[],
  violationTypes: ViolationFields[],
) {
  return violations.some(
    (violation: ViolationFields) => violationTypes.indexOf(violation) >= 0,
  );
}

export function checkAddressValidationResponse(
  addressCheckResult: addressValidationResponseFromAPI,
) {
  return (
    addressCheckResult.privateAddressCheckResult.adrKorOptions ||
    addressCheckResult.businessAddressCheckResult.adrKorOptions ||
    addressCheckResult.invoiceAddressCheckResult.adrKorOptions ||
    addressCheckResult.invoiceAddressCheckResult.adrKorOptions
  );
}

export function processValidationErrors(
  addressCheckResult: AddressValidationCheckResult,
  inputAddress: formAddress | FormBusinessAddress | FormDeviatingAddress,
  addressValidationResult: InternalSingleAddressValidationResult,
): boolean {
  if (addressCheckResult.streetCorrected && addressCheckResult.address) {
    inputAddress.street = addressCheckResult.address.street;
  }
  if (addressCheckResult.zipCodeCorrected && addressCheckResult.address) {
    inputAddress.zipCode = addressCheckResult.address.zipCode;
  }
  if (addressCheckResult.cityCorrected && addressCheckResult.address) {
    inputAddress.city = addressCheckResult.address.city;
  }
  if (addressCheckResult.postOfficeBoxCorrected && addressCheckResult.address) {
    inputAddress.postOfficeBox = addressCheckResult.address.postOfficeBox;
  }
  if (
    addressCheckResult.postOfficeBoxZipCodeCorrected &&
    addressCheckResult.address
  ) {
    inputAddress.postOfficeBoxZipCode =
      addressCheckResult.address.postOfficeBoxZipCode;
  }
  if (
    addressCheckResult.postOfficeBoxCityCorrected &&
    addressCheckResult.address
  ) {
    inputAddress.postOfficeBoxCity =
      addressCheckResult.address.postOfficeBoxCity;
  }

  addressValidationResult.hasErrors = addressCheckResult.hasErrors;
  addressValidationResult.postOfficeBoxError =
    addressCheckResult.postOfficeBoxError;
  addressValidationResult.cityError = addressCheckResult.cityError;
  addressValidationResult.streetError = addressCheckResult.streetError;
  addressValidationResult.streetNumberError =
    addressCheckResult.streetNumberError;
  addressValidationResult.zipCodeError = addressCheckResult.zipCodeError;
  addressValidationResult.corrected = addressCheckResult.corrected;
  addressValidationResult.streetCorrected = addressCheckResult.streetCorrected;
  addressValidationResult.zipCodeCorrected =
    addressCheckResult.zipCodeCorrected;
  addressValidationResult.cityCorrected = addressCheckResult.cityCorrected;
  addressValidationResult.postOfficeBoxCorrected =
    addressCheckResult.postOfficeBoxCorrected;
  addressValidationResult.postOfficeBoxCityCorrected =
    addressCheckResult.postOfficeBoxCityCorrected;
  addressValidationResult.postOfficeBoxZipCodeCorrected =
    addressCheckResult.postOfficeBoxZipCodeCorrected;

  return (
    addressCheckResult.streetError ||
    addressCheckResult.streetNumberError ||
    addressCheckResult.zipCodeError ||
    addressCheckResult.cityError ||
    addressCheckResult.postOfficeBoxError
  );
}

// TODO: Try to refactor and simplify
export function getOrderFormLogic(
  businessAddress: Ref<FormBusinessAddress> | null,
  deliveryAddress: Ref<FormDeviatingAddress> | null,
  invoiceAddress: Ref<FormDeviatingAddress> | null,
) {
  const orderFormData: Ref<OrderFormData> = ref(getEmptyPrivateAddress());

  const content = computed(() => store.getters.content);
  const isInvoiceSelected = computed(() => {
    return store.state.paymentType === PaymentMethods.INVOICE;
  });
  const countryOptions = computed(() => {
    return store.state.countryOptions;
  });
  const isPrintProduct = computed(() => store.getters.isPrintSubscription);

  // Data properties for sub-title processing
  const openSubTitleModal = ref(false);
  const subTitleCopy = ref('');
  const subTitleOutLink = ref('');

  // Validation related data properties
  const addressValidationResult: Ref<AddressValidationResult> = ref(
    getEmptyAddressValidationResult(),
  );

  const streetError = ref(false);
  const streetNumberError = ref(false);
  const cityError = ref(false);
  const streetCorrected = ref(false);
  const zipCodeCorrected = ref(false);
  const cityCorrected = ref(false);
  const addressIgnore = ref(false);

  const orderFormAddressIsInvalid = ref(false);
  const orderFormAddressHasEmptyFields = ref(false);
  const orderFormPersonalDataIsInvalid = ref(false);
  const orderFormOptionalFieldWrong = ref(false);

  const citySuggestion = ref(false);
  const addressOptions: AddressSuggestion[] = reactive([]);
  const cityOptions: SelectOption[] = reactive([]);
  const streetOptions: SelectOption[] = reactive([]);
  const addressSuggested = ref(false);
  const fullAddressNeeded = ref(false);

  const isValidating = ref(false);

  const requiredFieldsValid = computed(() => {
    return !(
      orderFormAddressHasEmptyFields.value ||
      orderFormPersonalDataIsInvalid.value
    );
  });

  const postOfficeBoxSelected = computed(() => {
    return (
      orderFormData.value.addressType === AddressTypeLiteral.POST_OFFICE_BOX
    );
  });

  const addressNeedsCompletion = computed(() => {
    return store.state.addressNeedsCompletion;
  });

  const needFullAddressResponse = computed(() => {
    return store.state.needsFullAddress;
  });

  const orderWithoutAddress = computed(() => {
    return OrderService.orderWithoutAddress();
  });

  const addressChanged = computed(() => {
    return (
      checkAddressChangedByValidation(
        addressValidationResult.value.privateAddress,
      ) ||
      checkAddressChangedByValidation(
        addressValidationResult.value.businessAddress,
      ) ||
      checkAddressChangedByValidation(
        addressValidationResult.value.deliveryAddress,
      ) ||
      checkAddressChangedByValidation(
        addressValidationResult.value.invoiceAddress,
      )
    );
  });

  const resetFieldErrors = function (): void {
    orderFormPersonalDataIsInvalid.value = false;
    orderFormAddressHasEmptyFields.value = false;
    orderFormOptionalFieldWrong.value = false;
  };

  const processViolationFieldsForAddress = function (
    violations: ViolationFields[],
  ) {
    if (!orderFormAddressHasEmptyFields.value) {
      orderFormAddressHasEmptyFields.value = checkViolationsForErrorTypes(
        violations,
        addressFields,
      );
    }
    if (!orderFormPersonalDataIsInvalid.value) {
      orderFormPersonalDataIsInvalid.value = checkViolationsForErrorTypes(
        violations,
        personalDataFields,
      );
    }
    if (!orderFormOptionalFieldWrong.value) {
      orderFormOptionalFieldWrong.value = checkViolationsForErrorTypes(
        violations,
        optionalFields,
      );
    }
  };

  const mapViolationFields = function (violations: AddressViolations): void {
    if (violations.privateAddress.length > 0) {
      processViolationFieldsForAddress(violations.privateAddress);
    } else if (violations.businessAddress.length > 0) {
      processViolationFieldsForAddress(violations.businessAddress);
    }

    if (violations.deliveryAddress.length > 0) {
      processViolationFieldsForAddress(violations.deliveryAddress);
    }

    if (violations.invoiceAddress.length > 0) {
      processViolationFieldsForAddress(violations.invoiceAddress);
    }
  };

  // Form submission data properties
  const formSubmitted = ref(false);
  const addressFormSubmitted = ref(false);

  // Form data
  const formData: ComputedRef<FormData> = computed(
    () => store.getters.formData,
  );
  const address: ComputedRef<formAddress> = computed(() => {
    return store.getters.formData.addresses.privateAddress
      ? store.getters.formData.addresses.privateAddress
      : createDummyAddress();
  });
  const goToCheckData = computed(() => {
    // Proceed to Verification only:
    // 1. gotoCheckData must be possible for SalesCampaign
    // 2. Campaign is NOT lwl, giftSubscription, student subscription, or young adult subscription
    // 3. SAP Connection did NOT fail (SAP is available)
    return (
      formData.value.gotoCheckData &&
      !(
        formData.value.salesCampaign.lwl ||
        formData.value.salesCampaign.giftSubscription ||
        formData.value.salesCampaign.studentSubscription ||
        formData.value.salesCampaign.ageLimit
      ) &&
      !formData.value.sapConnectionFailed
    );
  });

  // Form control
  const isAddressRequired = computed(() => {
    return (
      !orderWithoutAddress.value ||
      addressNeedsCompletion.value ||
      isInvoiceSelected.value ||
      fullAddressNeeded.value ||
      needFullAddressResponse.value
    );
  });

  const userExists = computed(() => {
    return address.value !== null;
  });
  const isNameEditable = computed(() => {
    if (store.state.formData.addresses.businessAddress) {
      return (
        address.value.nameEditable &&
        store.state.formData.addresses.businessAddress.nameEditable
      );
    } else {
      return address.value.nameEditable;
    }
  });
  const addressInvalid = computed(() => {
    return orderFormAddressIsInvalid.value;
  });

  const addressComplete = computed(() => {
    if (!isAddressRequired.value && requiredFieldsValid.value) {
      return true;
    } else {
      return requiredFieldsValid.value;
    }
  });

  const showRegionSelect = computed(() => {
    // return store.getters.content.config.region;
    return (
      store.getters.formData.regionSelectionOptions &&
      Object.keys(store.getters.formData.regionSelectionOptions).length > 0
    );
  });

  // Form functions
  const prefillForm = function () {
    const addressClone = address.value;

    if (!addressClone.country) {
      addressClone.country = getDefaultCountry(countryOptions);
    }

    const prefillAddress = countryOptions.value.some(
      (countryOption: { text: string; value: string }) =>
        countryOption.value === addressClone.country,
    );

    if (addressClone.salutation === '') {
      addressClone.salutation = '00';
    }
    // if (!addressClone.district) {
    //   addressClone.district = 'EG';
    // }

    if (!addressClone.title) {
      addressClone.title = '00';
    }

    orderFormData.value.salutation = addressClone.salutation;
    orderFormData.value.title = addressClone.title;
    orderFormData.value.firstName = addressClone.firstName;
    orderFormData.value.lastName = addressClone.lastName;

    if (prefillAddress) {
      orderFormData.value.country = addressClone.country;
      orderFormData.value.district = addressClone.district;
      orderFormData.value.street = addressClone.street
        ? addressClone.street
        : '';
      orderFormData.value.streetNumber = addressClone.streetNumber
        ? addressClone.streetNumber
        : '';
      orderFormData.value.postOfficeBox = addressClone.postOfficeBox
        ? addressClone.postOfficeBox
        : '';
      orderFormData.value.postOfficeBoxZipCode =
        addressClone.postOfficeBoxZipCode
          ? addressClone.postOfficeBoxZipCode
          : '';
      orderFormData.value.postOfficeBoxCity = addressClone.postOfficeBoxCity
        ? addressClone.postOfficeBoxCity
        : '';
      orderFormData.value.zipCode = addressClone.zipCode
        ? addressClone.zipCode
        : '';
      orderFormData.value.city = addressClone.city ? addressClone.city : '';
      orderFormData.value.additionalInfo = addressClone.additionalInfo
        ? addressClone.additionalInfo
        : '';
      orderFormData.value.floor = addressClone.floor ? addressClone.floor : '';
      orderFormData.value.room = addressClone.room ? addressClone.room : '';
      orderFormData.value.addressAddition = addressClone.addressAddition
        ? addressClone.addressAddition
        : '';

      orderFormData.value.nameEditable = addressClone.nameEditable;
    } else {
      orderFormData.value.country = '';
    }

    orderFormData.value.phone = addressClone.phone ? addressClone.phone : '';
    orderFormData.value.mobilePhone = addressClone.mobilePhone
      ? addressClone.mobilePhone
      : '';
    orderFormData.value.businessPhone = addressClone.businessPhone
      ? addressClone.businessPhone
      : '';

    if (store.state.userIsReceiver) {
      // Prefilling email form field:
      // 1. If initialFormData formAddress already contains an e-mail
      // 2. If not, check initialFormData attribute for login e-mail
      // 3. Else: set to default (empty string)
      if (addressClone.email) {
        orderFormData.value.email = addressClone.email;
      } else if (formData.value.email) {
        orderFormData.value.email = formData.value.email;
      } else {
        orderFormData.value.email = '';
      }
    }

    orderFormData.value.addressType = orderFormData.value.postOfficeBox
      ? AddressTypeLiteral.POST_OFFICE_BOX
      : AddressTypeLiteral.STREET_ADDRESS;
  };

  const submittingFormData = ref(false);

  const saveForm = function (address: FormAddresses) {
    store.commit('changeAddress', address);
    store.commit('changeIsFormValid', true);
    store.commit('changeAddressNeedsCompletion', false);
    store.commit('setAddressSubmittedSuccessfully', true);
  };

  const proceedAfterFormSubmit = () => {
    store.dispatch('loadAvailablePaymentMethods').then(() => {
      store.dispatch('updatePriceWithDeliveryDate').then(() => {
        submittingFormData.value = false;
        const showStudentView =
          !store.state.studentOrderFormWasShown &&
          store.state.formData.salesCampaign.studentSubscription;
        const showYoungAdultView =
          !store.state.youngAdultOrderFormWasShown &&
          store.state.formData.salesCampaign.ageLimit;

        if (showStudentView) {
          store.dispatch('activateStudentOrderFormWasShown');
        } else if (showYoungAdultView) {
          store.dispatch('activateYoungAdultOrderFormWasShown');
          store.dispatch(
            'updateAgeLimit',
            store.state.formData.salesCampaign.ageLimit,
          );
        } else if (OrderService.isFreeSubscription()) {
          router.push({ name: 'VerificationPage' });
        } else {
          router.push({ name: 'PaymentPage' });
        }
      });
    });
  };

  const closeModal = () => {
    openSubTitleModal.value = false;
    GtmService.pushGenericEvent({
      category: commonTexts.userFrontend.tracking.categories.checkout,
      action: commonTexts.userFrontend.tracking.subTitleChange.mailDelivery,
      label: store.state.formData.salesCampaign.salesCampaignId,
    });
    proceedAfterFormSubmit();
  };

  const resetAddressValidationResult = function () {
    addressValidationResult.value = getEmptyAddressValidationResult();
  };

  const submitAddress = async function (address: FormAddresses) {
    await FormService.validateAddress(address).then((response) => {
      resetAddressValidationResult();
      const addressCheckResult = response;

      isValidating.value = false;
      addressSuggested.value = false;

      if (
        // Full Address Needed only changes in digital orders, where business addresses are not valid
        addressCheckResult.privateAddressCheckResult.fullAddressNeeded &&
        orderWithoutAddress.value
      ) {
        store.commit('changeNeedsFullAddress', true);
      }

      // In case of errors: Scroll up to error message for user
      if (
        showErrorOrWarningToUserAfterAddressValidation(addressCheckResult) ||
        !addressComplete.value
      ) {
        scrollTo('main-order-form');
      }

      // Reset address ignore and invalid form flag, if address is ok
      if (!showErrorOrWarningToUserAfterAddressValidation(addressCheckResult)) {
        addressIgnore.value = false;
        orderFormAddressIsInvalid.value = false;
      }

      // Process response
      if (
        // Case 1 only can happen for private addresses in digital orders
        addressCheckResult.privateAddressCheckResult.fullAddressNeeded &&
        !postOfficeBoxSelected.value
      ) {
        fullAddressNeeded.value = true;
        submittingFormData.value = false;
        // Case 2: Address is invalid, user will be prompted to correct or ignore and re-submit
      } else if (addressInvalid.value && !addressIgnore.value) {
        orderFormAddressIsInvalid.value = false;
        addressIgnore.value = true;
        submittingFormData.value = false;
        // Case 3: CorrectionOptions exist
      } else if (checkAddressValidationResponse(addressCheckResult)) {
        if (addressCheckResult) {
          setAddressesCorrectionOptions(
            addressCheckResult,
            addressValidationResult,
          );
        }
        submittingFormData.value = false;
        // Case 4: Values were corrected or errors can be ignored next time:
      } else if (
        showErrorOrWarningToUserAfterAddressValidation(addressCheckResult) &&
        !addressIgnore.value
      ) {
        if (addressCheckResult.privateAddressCheckResult.address) {
          orderFormAddressIsInvalid.value = processValidationErrors(
            addressCheckResult.privateAddressCheckResult,
            orderFormData.value,
            addressValidationResult.value?.privateAddress,
          );
        }

        if (
          businessAddress?.value &&
          addressCheckResult.businessAddressCheckResult.address
        ) {
          orderFormAddressIsInvalid.value = processValidationErrors(
            addressCheckResult.businessAddressCheckResult,
            businessAddress.value,
            addressValidationResult.value?.businessAddress,
          );
        }

        if (
          deliveryAddress?.value &&
          addressCheckResult.deliveryAddressCheckResult.address
        ) {
          orderFormAddressIsInvalid.value = processValidationErrors(
            addressCheckResult.deliveryAddressCheckResult,
            deliveryAddress.value,
            addressValidationResult.value?.deliveryAddress,
          );
        }

        if (
          invoiceAddress?.value &&
          addressCheckResult.invoiceAddressCheckResult.address
        ) {
          orderFormAddressIsInvalid.value = processValidationErrors(
            addressCheckResult.invoiceAddressCheckResult,
            invoiceAddress.value,
            addressValidationResult.value?.invoiceAddress,
          );
        }
        submittingFormData.value = false;
      } else {
        saveForm(address);
        if (
          // SubTitle Case: Print, Response with copy and outlink
          isPrintProduct.value &&
          response.subTitleOutLink &&
          response.subTitleOutLink.length > 0 &&
          response.subTitleInfoTextParagraphs &&
          response.subTitleInfoTextParagraphs.length > 0
        ) {
          subTitleOutLink.value = response.subTitleOutLink;

          let subTitleModalCopy = '';
          for (let i = 0; i < response.subTitleInfoTextParagraphs.length; i++) {
            subTitleModalCopy = subTitleModalCopy.concat(
              '<p>',
              response.subTitleInfoTextParagraphs[i].replace('\n', '<br>'),
              '</p>',
            );
          }
          subTitleCopy.value = subTitleModalCopy;
          GtmService.pushGenericEvent({
            category: commonTexts.userFrontend.tracking.categories.checkout,
            action: commonTexts.userFrontend.tracking.subTitleChange.showModal,
            label: store.state.formData.salesCampaign.salesCampaignId,
          });
          openSubTitleModal.value = true;
        } else {
          proceedAfterFormSubmit();
        }
      }
    });
  };

  const submitOrderForm = async function (event: ValidationResultOrderForm) {
    resetFieldErrors();
    submittingFormData.value = true;

    if (event.result === ValidationResultValue.SUCCESS) {
      isValidating.value = true;

      if (formSubmitted.value || isAddressRequired.value) {
        addressFormSubmitted.value = true;
      }
      formSubmitted.value = true;
      store.commit('changeIsAddressComplete', true);
      await submitAddress(event.validatedData);
    } else if (event.result === ValidationResultValue.FIELD_VIOLATIONS) {
      store.commit('changeIsAddressComplete', false);
      mapViolationFields(event.violations);
      scrollTo('main-order-form');
      submittingFormData.value = false;
    }
  };

  return {
    orderFormData,
    postOfficeBoxSelected,
    streetError,
    streetNumberError,
    cityError,
    streetCorrected,
    zipCodeCorrected,
    cityCorrected,
    addressIgnore,
    citySuggestion,
    addressOptions,
    cityOptions,
    streetOptions,
    addressSuggested,
    fullAddressNeeded,
    isValidating,
    formSubmitted,
    addressFormSubmitted,
    orderFormAddressIsInvalid,
    orderFormAddressHasEmptyFields,
    orderFormPersonalDataIsInvalid,
    orderFormOptionalFieldWrong,
    content,
    formData,
    address,
    userExists,
    isNameEditable,
    addressInvalid,
    addressComplete,
    addressChanged,
    goToCheckData,
    countryOptions,
    prefillForm,
    showRegionSelect,
    submitOrderForm,
    orderWithoutAddress,
    isAddressRequired,
    addressValidationResult,
    submittingFormData,
    closeModal,
    openSubTitleModal,
    subTitleCopy,
    subTitleOutLink,
  };
}

export function getEmptyDeviatingAddress(): FormDeviatingAddress {
  return {
    addressType: AddressTypeLiteral.STREET_ADDRESS,
    addressNumber: '',
    salutation: '',
    district: '',
    nameEditable: true,
    street: '',
    streetNumber: '',
    postOfficeBox: '',
    postOfficeBoxZipCode: '',
    postOfficeBoxCity: '',
    zipCode: '',
    city: '',
    country: '',
    email: '',
    phone: '',
    businessPhone: '',
    mobilePhone: '',
    companySuffix1: '',
    companySuffix2: '',
    companySuffix3: '',
    mailDeliverySelected: false,
    additionalInfo: '',
    floor: '',
    majorCustomerZipCode: '',
    majorCustomerCity: '',
  };
}

export function getEmptyBusinessAddress(): FormBusinessAddress {
  return {
    addressType: AddressTypeLiteral.STREET_ADDRESS,
    addressNumber: '',
    district: '',
    nameEditable: true,
    salutation: '00',
    companyName: '',
    street: '',
    streetNumber: '',
    postOfficeBox: '',
    postOfficeBoxZipCode: '',
    postOfficeBoxCity: '',
    zipCode: '',
    city: '',
    country: '',
    email: '',
    phone: '',
    businessPhone: '',
    mobilePhone: '',
    companySuffix1: '',
    companySuffix2: '',
    companySuffix3: '',
    mailDeliverySelected: false,
    additionalInfo: '',
    floor: '',
    majorCustomerZipCode: '',
    majorCustomerCity: '',
  };
}

export function getBusinessAddressFunctionality() {
  const businessAddress = ref(getEmptyBusinessAddress());
  const deliveryAddress: Ref<FormDeviatingAddress> = ref(
    getEmptyDeviatingAddress(),
  );
  const invoiceAddress = ref(getEmptyDeviatingAddress());

  // TODO: Implement salesCampaign values
  const gpTypeSelectionPossible = computed(() => {
    return store.getters.formData.salesCampaign.showGPTypeSelection;
  });
  const deliveryAddressPossible = computed(() => {
    return store.getters.formData.salesCampaign.showDeliveryAddressCompany;
  });
  const invoiceAddressPossible = computed(() => {
    return store.getters.formData.salesCampaign.showInvoiceAddressCompany;
  });

  return {
    businessAddress,
    deliveryAddress,
    invoiceAddress,
    gpTypeSelectionPossible,
    deliveryAddressPossible,
    invoiceAddressPossible,
  };
}

export function deleteAddress(address: FormDeviatingAddress) {
  address.addressType = AddressTypeLiteral.STREET_ADDRESS;
  address.street = '';
  address.streetNumber = '';
  address.postOfficeBox = '';
  address.postOfficeBoxZipCode = '';
  address.postOfficeBoxCity = '';
  address.zipCode = '';
  address.city = '';
  address.country = '';
  address.email = '';
  address.phone = '';
  address.businessPhone = '';
  address.mobilePhone = '';
  address.companySuffix1 = '';
  address.companySuffix2 = '';
  address.companySuffix3 = '';
  address.mailDeliverySelected = false;
  address.additionalInfo = '';
  address.floor = '';
  address.majorCustomerZipCode = '';
  address.majorCustomerCity = '';
}

export function buildAddressFromFormData(
  privateAddress: formAddress,
  businessAddress: FormBusinessAddress | null,
  deliveryAddress: FormDeviatingAddress | null,
  invoiceAddress: FormDeviatingAddress | null,
  isBusinessAddress: boolean,
) {
  const address: FormAddresses = {
    privateAddress: null,
    businessAddress: null,
    deliveryAddress: null,
    invoiceAddress: null,
  };

  if (isBusinessAddress) {
    address.businessAddress = businessAddress;
    address.deliveryAddress = deliveryAddress;
    address.invoiceAddress = invoiceAddress;
  } else {
    address.privateAddress = privateAddress;
  }

  return address;
}

export function prefillBusinessData(
  address: FormBusinessAddress | FormDeviatingAddress,
  correspondingFormAddress: FormBusinessAddress | FormDeviatingAddress | null,
  prefillEmail: boolean,
) {
  const { countryOptions } = getOrderFormLogic(null, null, null);

  if (correspondingFormAddress !== null) {
    address = correspondingFormAddress;
  }

  if (!address.country) {
    address.country = getDefaultCountry(countryOptions);
  }
  if (address.salutation === '') {
    address.salutation = '00';
  }

  const prefillAddress = countryOptions.value.some(
    (countryOption: { text: string; value: string }) =>
      countryOption.value === address.country,
  );

  if (!prefillAddress) {
    address.country = '';
    address.district = '';
    address.companySuffix1 = '';
    address.companySuffix2 = '';
    address.companySuffix3 = '';
    address.street = '';
    address.streetNumber = '';
    address.postOfficeBox = '';
    address.postOfficeBoxZipCode = '';
    address.postOfficeBoxCity = '';
    address.zipCode = '';
    address.city = '';
    address.additionalInfo = '';
    address.floor = '';
  }

  if (prefillEmail) {
    // Prefilling email form field:
    // 1. If initialFormData formAddress already contains an e-mail
    // 2. If not, check initialFormData attribute for login e-mail
    // 3. Else: set to default (empty string)
    if (!address.email && store.state.formData.email) {
      address.email = store.state.formData.email;
    } else if (!address.email && !store.state.formData.email) {
      address.email = '';
    }
  }

  address.addressType = address.postOfficeBox
    ? AddressTypeLiteral.POST_OFFICE_BOX
    : AddressTypeLiteral.STREET_ADDRESS;

  return address;
}

export function checkIfDeviatingAddressExists(
  address: FormDeviatingAddress | null,
) {
  // If address is null: false
  if (!address) return false;

  // Address exists, if at least one property is given:
  return !(
    address.street === '' &&
    address.streetNumber === '' &&
    address.zipCode === '' &&
    address.city === '' &&
    address.postOfficeBox === '' &&
    address.postOfficeBoxCity === '' &&
    address.postOfficeBoxZipCode === ''
  );
}
