<template>
  <div class="student-enrollment-upload-form">
    <TheOverlay
      id="wrapperFormElementFileUpload"
      :show="uploadInProgress"
      :opacity="1"
      :isFullPage="false"
    >
      <FormElementFileUpload
        :value="fileName"
        :caption="commonTexts.userFrontend.studentEnrollmentForm.uploadCaption"
        :has-error="uploadError"
        :message="uploadMessage"
        :upload-success="uploadSuccess"
        :show-preview-image-after-upload="false"
        id="orderBonusCustomizingFileUpload"
        @fileSelected="uploadFile($event)"
        @imageDeleted="removeImage"
        @unsupportedFileTypeError="setInternalValidationError"
        valid-file-formats=".jpg,.jpeg,.png,.gif,.pdf"
        class="mt-3"
      />
    </TheOverlay>
  </div>
</template>

<script lang="ts">
  import FormElementFileUpload from '@/components/FormElements/FormElementFileUpload.vue';
  import commonTexts from '@/data/commonTexts';
  import { defineComponent, ref } from 'vue';
  import { ImageUploadResponseMessage } from '@/types/ImageUploadTypes';
  import errorMessages from '@/data/errorMessages';
  import { AxiosError } from 'axios';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import TheOverlay from '@/components/Overlay/TheOverlay.vue';

  export default defineComponent({
    name: 'StudentEnrollmentUploadForm',
    components: { FormElementFileUpload, TheOverlay },
    props: {
      fileName: {
        type: String,
        required: true,
      },
    },
    emits: ['imageUploaded'],
    setup(_, { emit }) {
      const store = useStore();
      const router = useRouter();

      const uploadError = ref(false);
      const uploadMessage = ref('');
      const uploadSuccess = ref(false);
      const uploadInProgress = ref(false);

      const removeImage = function () {
        store.dispatch('resetStudentEnrollmentUpload').then(() => {
          uploadMessage.value = '';
          uploadSuccess.value = false;
        });
      };

      const setInternalValidationError = function () {
        uploadSuccess.value = false;
        uploadError.value = true;
        uploadMessage.value =
          errorMessages.userInterfaceOnly.studentEnrollmentForm.imageUploadErrors.fileTypeInvalid;
      };

      const handleUploadResponseMessage = function (
        message: ImageUploadResponseMessage,
      ) {
        switch (message) {
          case ImageUploadResponseMessage.FILE_SIZE_EXCEEDED:
            uploadSuccess.value = false;
            uploadError.value = true;
            uploadMessage.value =
              errorMessages.userInterfaceOnly.studentEnrollmentForm.imageUploadErrors.fileTooLarge;
            break;
          case ImageUploadResponseMessage.INVALID_FILE_NAME:
            uploadSuccess.value = false;
            uploadError.value = true;
            uploadMessage.value =
              errorMessages.userInterfaceOnly.studentEnrollmentForm.imageUploadErrors.fileNameInvalid;
            break;
          case ImageUploadResponseMessage.INVALID_FILE_TYPE:
            uploadSuccess.value = false;
            uploadError.value = true;
            uploadMessage.value =
              errorMessages.userInterfaceOnly.studentEnrollmentForm.imageUploadErrors.fileTypeInvalid;
            break;
          case ImageUploadResponseMessage.INVALID_SUFFIX:
            uploadSuccess.value = false;
            uploadError.value = true;
            uploadMessage.value =
              errorMessages.userInterfaceOnly.studentEnrollmentForm.imageUploadErrors.fileTypeInvalid;
            break;
          case ImageUploadResponseMessage.NO_FILE:
            uploadSuccess.value = false;
            uploadError.value = true;
            uploadMessage.value =
              errorMessages.userInterfaceOnly.studentEnrollmentForm.imageUploadErrors.noFile;
            break;
          case ImageUploadResponseMessage.SERVER_ERROR:
            uploadSuccess.value = false;
            uploadError.value = true;
            uploadMessage.value =
              errorMessages.userInterfaceOnly.studentEnrollmentForm.imageUploadErrors.noFile;
            break;
          case ImageUploadResponseMessage.SUCCESS:
            uploadError.value = false;
            uploadSuccess.value = true;
            uploadMessage.value =
              commonTexts.admin.orderBonusCustomizing.imageUploadSuccess;
            break;
          default:
            uploadSuccess.value = false;
            uploadError.value = true;
            uploadMessage.value =
              errorMessages.userInterfaceOnly.studentEnrollmentForm.imageUploadErrors.noFile;
        }
      };

      const uploadFile = function (file: FileList) {
        uploadInProgress.value = true;
        store
          .dispatch('uploadStudentEnrollmentCertificate', file[0])
          .then((message: ImageUploadResponseMessage) => {
            handleUploadResponseMessage(message);
            emit('imageUploaded');
            uploadInProgress.value = false;
          })
          .catch((error: AxiosError) => {
            switch (error.response?.status) {
              case 400:
                uploadError.value = true;
                uploadMessage.value =
                  errorMessages.userInterfaceOnly.studentEnrollmentForm.imageUploadErrors.noFile;
                break;
              case 413:
                uploadSuccess.value = false;
                uploadError.value = true;
                uploadMessage.value =
                  errorMessages.userInterfaceOnly.studentEnrollmentForm.imageUploadErrors.fileTooLarge;
                break;
              case 500:
                uploadSuccess.value = false;
                uploadError.value = true;
                uploadMessage.value =
                  errorMessages.userInterfaceOnly.studentEnrollmentForm.imageUploadErrors.general;
                break;
              default:
                console.log(error.response?.data);
                router.push({
                  name: 'ErrorPage',
                  params: { type: 'server' },
                });
            }
            uploadInProgress.value = false;
          });
      };

      return {
        commonTexts,
        uploadError,
        uploadMessage,
        uploadSuccess,
        uploadFile,
        removeImage,
        setInternalValidationError,
        handleUploadResponseMessage,
        uploadInProgress,
      };
    },
  });
</script>
