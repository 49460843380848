import { CustomizingConnectorInterface } from '@/services/connectors/interfaces';
import { CreateComplexOrderBonusResponseCode } from '@/services/connectors/types';
import {
  BasicOrderBonus,
  OrderBonus,
  OrderBonusClassification,
  SalesCampaignOrderBonus,
} from '@/types/OrderBonusesTypes';
import { AppConfiguration } from '@/store/modules/config/types';
import { stubDataAllBonuses } from '@/services/connectors/stub-data-all-bonuses';
import {
  AxiosRequestHeaders,
  AxiosResponse,
  AxiosResponseHeaders,
} from 'axios';
import { availableBonuses } from '@/services/connectors/stub-data';

// const emptyResponse = {
//   headers: new Headers(),
//   ok: true,
//   redirected: false,
//   status: 200,
//   statusText: 'ok',
//   type: 'default' as ResponseType,
//   url: '',
//   body: null,
//   bodyUsed: false,
//   formData: null,
//   json: null,
//   text: null,
//   blob: null,
//   clone: () => new Response(),
//   arrayBuffer: null,
// };
const getEmptyResponse = (body?: string, options?: { status: number }) =>
  new Response(body, options);
const getEmptyAxiosResponse = (body?: string, options?: { status: number }) => {
  const response = getEmptyResponse(body, options);
  return {
    data: response,
    status: response.status,
    statusText: response.statusText,
    headers: response.headers as unknown as AxiosResponseHeaders,
    config: { headers: {} as AxiosRequestHeaders }, // resolving type compatibility issue
  };
};

export default class CustomizingConnectorStub
  implements CustomizingConnectorInterface
{
  createComplexOrderBonus(
    materialNumber: string,
    orderBonus: OrderBonus,
  ): Promise<AxiosResponse> {
    console.log('Creating complex order bonus in stub');
    console.log('Material number: ' + materialNumber);
    console.log('Order bonus: ' + orderBonus);
    return new Promise((resolve) => {
      setTimeout(() => {
        const response = getEmptyAxiosResponse('', {
          status: CreateComplexOrderBonusResponseCode.SUCCESS,
        });

        resolve(response);
      }, 500);
    });
  }

  deleteComplexOrderBonus(materialNumber: string): Promise<Response> {
    console.log('Deleting complex order bonus in stub');
    console.log('Material number: ' + materialNumber);
    return Promise.resolve(getEmptyResponse());
  }

  getAvailableOrderBonusesForSalesCampaign(
    salesCampaignId: string,
  ): Promise<SalesCampaignOrderBonus[]> {
    console.log('Getting stubbed available order bonuses for sales campaign');
    console.log('Sales campaign ID: ' + salesCampaignId);
    let bonuses: SalesCampaignOrderBonus[];
    switch (salesCampaignId.toLowerCase()) {
      case 'dszbk':
        bonuses = availableBonuses.dszbk;
        break;
      default:
        bonuses = [];
    }
    return Promise.resolve(bonuses);
  }

  getConfiguration(): Promise<AppConfiguration> {
    console.log('Getting stubbed configuration');
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          urls: {
            loginUrl: 'https://www.google.com/login',
            logoutUrl: 'https://www.google.com/logout',
            registerUrl: window.location.origin + '/registrationSuccess',
          },
        });
      }, 500);
    });
  }

  getOrderBonus(materialNumber: string): Promise<OrderBonus> {
    return Promise.resolve({
      materialNumber: materialNumber,
      label: 'Stubbed Bonus',
      // editable
      active: true,
      title: 'Stubbed Bonus Title',
      validFrom: '2020-01-01',
      cashBonus: false,
      additionalCharges: [],
      // editable
      textLabel: 'TextLabel',
      text: 'Lorem ipsum text',
      linkLabel: 'LinkLabel',
      link: 'https://www.google.com/link',
      infoPoints: [],
      imagePath: 'https://picsum.photos/493/315',
      classification: OrderBonusClassification.NON_MONETARY,
      properties: [],
      variants: [],
      invalid: false,
    });
  }

  getOrderBonuses(getActiveBonusesOnly: boolean): Promise<BasicOrderBonus[]> {
    console.log('Getting stubbed order bonuses');
    console.log('Get active bonuses only: ' + getActiveBonusesOnly);
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          getActiveBonusesOnly
            ? stubDataAllBonuses.filter((bonus) => bonus.active)
            : stubDataAllBonuses,
        );
      }, 500);
    });
  }

  updateOrderBonus(
    materialNumber: string,
    orderBonus: OrderBonus,
  ): Promise<Response> {
    console.log('Updating order bonus in stub');
    console.log('Material number: ' + materialNumber);
    console.log('Order bonus: ' + orderBonus);
    return Promise.resolve(getEmptyResponse());
  }
}
