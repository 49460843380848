import store from '@/store';
import router from '@/router';
import SetupService from '@/services/setup-service';

export default class NavService {
  public static getBackLinkSrc() {
    if (store.state.parameters.sourceUrl) {
      return store.state.parameters.sourceUrl;
    } else {
      return SetupService.getContent().success.backLinkSrc;
    }
  }

  public static getCurrentRouteName(): string {
    return router.currentRoute.value.name?.toString() || '';
  }

  public static isRouteExitPoint(): boolean {
    const exitPointRoutes = [
      'Start',
      'SuccessPage',
      'ErrorPage',
      'FollowUpOffer',
      'OrderExists',
    ];
    return (
      this.getCurrentRouteName &&
      exitPointRoutes.includes(this.getCurrentRouteName())
    );
  }

  public static handleStepBack() {
    if (
      this.isRouteExitPoint() &&
      // Additional conditions for Start page:
      //    1. OrderBonusCart was not yet confirmed and
      //    2. StudentOrderForm was not yet shown
      !store.state.orderBonusCartConfirmed &&
      !store.state.studentOrderFormWasShown &&
      !store.state.youngAdultOrderFormWasShown
    ) {
      window.location = this.getBackLinkSrc() as unknown as Location;
    } else {
      this.goBackInPath();
    }
  }

  public static goBackInPath() {
    switch (this.getCurrentRouteName()) {
      case 'Start':
        if (store.state.studentOrderFormWasShown) {
          store.dispatch('resetStudentOrderFormWasShown');
        } else if (store.state.recruiterAddressesSaved) {
          store.dispatch('resetRecruiterAddressesSaved');
        } else if (store.state.orderBonusCartConfirmed) {
          store.dispatch('resetOrderBonusCart');
        }
        break;
      case 'PaymentPage':
        if (store.state.paymentMethodSelected) {
          store.dispatch('resetPaymentMethodSelected');
        } else {
          router.push({ name: 'Start' });
        }
        break;
      case 'VerificationPage':
        if (store.state.billingFrequencySelected) {
          store.dispatch('resetBillingFrequencySelected').then(() => {
            router.push({ name: 'PaymentPage' });
          });
        } else {
          store.dispatch('resetPaymentMethodSelected').then(() => {
            router.push({ name: 'PaymentPage' });
          });
        }
        break;
      case 'Internal':
        router.push({ name: 'Start' });
        break;
      case 'UserManagement':
        router.push({ name: 'Internal' });
        break;
      case 'SalesCampaignsApproval':
        router.push({ name: 'Internal' });
        break;
      case 'SalesCampaignsOverview':
        router.push({ name: 'Internal' });
        break;
      case 'SalesCampaignCustomizing':
        router.push({ name: 'SalesCampaignsOverview' });
        break;
      case 'OrderBonusesOverview':
        router.push({ name: 'Internal' });
        break;
      case 'OrderBonusCustomizing':
        router.push({ name: 'OrderBonusesOverview' });
        break;
      case 'ComplexOrderBonusCreation':
        router.push({ name: 'OrderBonusesOverview' });
        break;
      default:
        break;
    }
  }
}
