<template>
  <ContentArea>
    <div>
      <div class="order-young-adults-view">
        <header>
          <h2 class="main-content-title">
            {{ getHeadline() }}
          </h2>
        </header>
        <div class="order-young-adults-view__copy">
          <p v-html="getCopy()"></p>
        </div>
        <div class="order-young-adults-view__form margin-top-large">
          <!-- birth date -->
          <p class="fw-bold">
            {{
              commonTexts.userFrontend.youngAdultSubscriptionForm
                .bithDateHeadline
            }}
          </p>
          <div
            v-if="birthDateErrorHintMessage"
            class="error-message margin-top-medium error-info fw-bold"
            v-html="birthDateErrorHintMessage"
          ></div>
          <div
            v-if="birthDateHasError"
            class="error-message margin-top-medium"
            v-html="birthDateErrorMessage"
          ></div>
          <div class="margin-top-base">
            <FormElementInputField
              :value="birthDate"
              @input="setBirthDate($event)"
              @blur="formatBirthDate"
              :label="
                commonTexts.userFrontend.youngAdultSubscriptionForm
                  .birthDateLabel
              "
              :field-has-error="birthDateHasError"
            />
          </div>

          <!-- id card inputs -->
          <div class="margin-top-large">
            <p class="fw-bold">
              {{
                commonTexts.userFrontend.youngAdultSubscriptionForm
                  .idCardHeadline
              }}
            </p>
            <p
              class="fs-small"
              v-html="
                commonTexts.userFrontend.youngAdultSubscriptionForm.idCardText
              "
            ></p>
            <div
              v-if="idCardError"
              v-html="idCardErrorMessage"
              class="error-message margin-top-medium"
            ></div>
            <div class="id-inputs">
              <!-- first line -->
              <div class="first-line">
                <span class="arrow" v-html="'IDD <<'"></span>
                <FormElementInputField
                  :value="mrz.line1Field1"
                  @input="setMrz('line1Field1', $event)"
                  :label="'L01X00T471'"
                  :field-has-error="idCardError"
                  maxlength="10"
                  class="document-number-full"
                />
                <span class="arrow" v-html="'<<<<<<<<<<<<<<<'"></span>
              </div>
              <!-- second line -->
              <div class="second-line">
                <FormElementInputField
                  :value="mrz.line2Field1"
                  @input="setMrz('line2Field1', $event)"
                  :label="'8308126'"
                  :field-has-error="idCardError"
                  maxlength="7"
                  class="birth-date-full"
                />
                <span class="arrow" v-html="'<'"></span>
                <FormElementInputField
                  :value="mrz.line2Field2"
                  @input="setMrz('line2Field2', $event)"
                  :label="'3108011D'"
                  :field-has-error="idCardError"
                  maxlength="8"
                  class="expiry-date-full"
                />
                <span class="arrow" v-html="'<<'"></span>
                <FormElementInputField
                  :value="mrz.line2Field3"
                  @input="setMrz('line2Field3', $event)"
                  :label="'2108'"
                  :field-has-error="idCardError"
                  maxlength="4"
                  class="serial-number"
                />
                <span class="arrow" v-html="'<<<<<<<'"></span>
                <FormElementInputField
                  :value="mrz.line2Field4"
                  @input="setMrz('line2Field4', $event)"
                  :label="'7'"
                  :field-has-error="idCardError"
                  maxlength="1"
                  class="check-digit"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="order-young-adults-view__info margin-top-large">
          <p class="fw-bold fs-small">
            {{
              commonTexts.userFrontend.youngAdultSubscriptionForm
                .idCardInfoHeadline
            }}
          </p>
          <p
            class="fs-small"
            v-html="
              commonTexts.userFrontend.youngAdultSubscriptionForm.idCardInfoText
            "
          ></p>
          <img src="/images/id-card-example.png" width="226" alt="" />
        </div>
        <div class="order-young-adults-view__submit margin-top-large">
          <BasicButton
            variant="primary"
            type="submit"
            @buttonClicked="validateAndProceedToPayment"
          >
            {{
              commonTexts.userFrontend.youngAdultSubscriptionForm
                .submitButtonLabel
            }}</BasicButton
          >
        </div>
        <div class="order-young-adults-view__info margin-top-large">
          <p
            class="fs-small"
            v-html="content.youngAdultSubscriptionForm.textInterest"
          ></p>
        </div>
      </div>
    </div>
  </ContentArea>
</template>

<script lang="ts">
  import commonTexts from '@/data/commonTexts';
  import { reactive, ref, defineComponent, onMounted, watch } from 'vue';
  import GtmService from '@/services/gtm-service';
  import ContentArea from '@/components/PageLayout/ContentArea.vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import BasicButton from '@/components/Buttons/BasicButton.vue';
  import {
    DateFormat,
    DocumentParts,
    Info,
    ValidationResult,
  } from '@/models/IDCardModels';
  import { mrz } from '@/models/OrderData';
  import FormElementInputField from '@/components/FormElements/FormElementInputField.vue';
  import { computed, ComputedRef } from 'vue';
  import store from '@/store';

  export default defineComponent({
    name: 'OrderYoungAdults',
    components: { ContentArea, BasicButton, FormElementInputField },
    props: {
      prefillFormData: {
        type: Boolean,
        required: true,
      },
      ageLimit: {
        type: Number,
        required: true,
      },
    },
    emits: ['formPrefilled'],
    setup(props, { emit }) {
      const store = useStore();
      const router = useRouter();
      watch(props, () => {
        if (props.prefillFormData) {
          emit('formPrefilled');
        }
      });

      onMounted(() => {
        GtmService.pushGenericEvent({
          category: 'Checkout',
          action: 'U-Abo',
          label: store.getters.formData.salesCampaign.salesCampaignId,
        });
      });

      const validationResult = ref<ValidationResult | null>(null);
      const isValidExpiry = ref(true);
      const birthDateHasError = ref(false);
      const birthDateErrorMessage = ref<string>('');
      const birthDateErrorHintMessage = ref<string>('');
      const idCardError = ref(false);
      const idCardErrorMessage = ref<string>('');

      const content = computed(() => store.getters.content);

      const birthDate = computed(() => {
        return store.state.youngAdultsData.birthDate;
      });

      const mrz: ComputedRef<mrz> = computed(() => {
        return store.state.youngAdultsData.mrz;
      });

      const setBirthDate = (value: string) => {
        store.commit('setYoungAdultsDataBirthDate', value);
      };

      const setMrz = (key: string, value: string) => {
        const tempMrz = mrz.value;
        tempMrz[key as keyof mrz] = value;
        store.dispatch('updateYoungAdultsDataMrz', tempMrz);
      };

      const formatBirthDate = () => {
        const date = birthDate.value.split('.');
        if (date.length !== 3) {
          return;
        }
        if (date[0].length === 1) {
          date[0] = `0${date[0]}`;
        }
        if (date[1].length === 1) {
          date[1] = `0${date[1]}`;
        }
        store.commit('setYoungAdultsDataBirthDate', date.join('.'));
      };

      const getHeadline = () => {
        const age = props.ageLimit;
        return content.value.youngAdultSubscriptionForm.headline.replace(
          '{age}',
          age.toString(),
        );
      };

      const getCopy = () => {
        const age = props.ageLimit;
        return content.value.youngAdultSubscriptionForm.copy.replace(
          '{age}',
          age.toString(),
        );
      };

      const WEIGHTS = [7, 3, 1];

      const toICAOEncoded = (char: string): number => {
        if (char >= '0' && char <= '9') {
          return parseInt(char);
        }
        if (char === '<') {
          return 0;
        }
        return char.charCodeAt(0) - 'A'.charCodeAt(0) + 10;
      };

      const addWeights = (encodedData: number[]): number[] => {
        return encodedData.map(
          (value, index) => value * WEIGHTS[index % WEIGHTS.length],
        );
      };

      const calculateCheckDigit = (data: string): number => {
        const encodedData = Array.from(data).map(toICAOEncoded);
        const weightedData = addWeights(encodedData);
        const sum = weightedData.reduce((acc, val) => acc + val, 0);
        return sum % 10;
      };

      const isDataWithCheckDigitValid = (
        dataWithCheckDigit: string,
      ): boolean => {
        const data = dataWithCheckDigit.slice(0, -1);
        const checkDigit = parseInt(
          dataWithCheckDigit[dataWithCheckDigit.length - 1],
        );
        return calculateCheckDigit(data) === checkDigit;
      };

      const getFullYear = (yearPrefix: string): string => {
        const currentYear = new Date().getFullYear().toString().slice(-2);
        return parseInt(yearPrefix) > parseInt(currentYear)
          ? `19${yearPrefix}`
          : `20${yearPrefix}`;
      };

      const calculateAge = (birthDateStr: string): number => {
        const year = parseInt(getFullYear(birthDateStr.slice(0, 2)));
        const month = parseInt(birthDateStr.slice(2, 4)) - 1;
        const day = parseInt(birthDateStr.slice(4, 6));

        const birthDate = new Date(year, month, day);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();

        if (
          today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() &&
            today.getDate() < birthDate.getDate())
        ) {
          age--;
        }

        return age;
      };

      const formatDate = (date: DateFormat): string => {
        return `${date.day}.${date.month}.${date.year}`;
      };

      const checkExpiry = (info: Info) => {
        const expiryDate = new Date(
          parseInt(info.expiryDate.year),
          parseInt(info.expiryDate.month) - 1,
          parseInt(info.expiryDate.day),
        );
        isValidExpiry.value = expiryDate > new Date();
      };

      const validateAge = (date: string) => {
        const inputDate = new Date(date);
        const today = new Date();
        let age = today.getFullYear() - inputDate.getFullYear();
        const monthDiff = today.getMonth() - inputDate.getMonth();

        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < inputDate.getDate())
        ) {
          age--;
        }

        const maxAge = props.ageLimit;
        return {
          isValid: age >= 18 && age < maxAge,
          age,
        };
      };

      const isValidDate = (dateString: string): boolean => {
        // Check format DD.MM.YYYY
        if (!/^\d{2}\.\d{2}\.\d{4}$/.test(dateString)) {
          return false;
        }

        const [day, month, year] = dateString.split('.').map(Number);
        const date = new Date(year, month - 1, day);

        return (
          date instanceof Date &&
          !isNaN(date.getTime()) &&
          date.getDate() === day &&
          date.getMonth() === month - 1 &&
          date.getFullYear() === year
        );
      };

      const validateBirthDate = (): boolean => {
        if (!birthDate.value || !isValidDate(birthDate.value)) {
          birthDateHasError.value = true;
          birthDateErrorMessage.value =
            commonTexts.userFrontend.youngAdultSubscriptionForm.errorBirthDate;
          birthDateErrorHintMessage.value = '';
          return false;
        }

        const [day, month, year] = birthDate.value.split('.').map(Number);
        const dateForValidation = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const { isValid, age } = validateAge(dateForValidation);

        birthDateHasError.value = !isValid;

        if (!isValid) {
          if (age < 18) {
            birthDateErrorMessage.value =
              commonTexts.userFrontend.youngAdultSubscriptionForm.errorTooYoung;
            birthDateErrorHintMessage.value =
              content.value.youngAdultSubscriptionForm.errorTooYoung;
          } else {
            birthDateErrorMessage.value =
              commonTexts.userFrontend.youngAdultSubscriptionForm.errorTooOld;
            birthDateErrorHintMessage.value =
              content.value.youngAdultSubscriptionForm.errorTooOld.replace(
                '{age-1}',
                (props.ageLimit - 1).toString(),
              );
          }
          return false;
        }

        birthDateHasError.value = false;
        birthDateErrorMessage.value = '';
        birthDateErrorHintMessage.value = '';
        return true;
      };

      const validateAndProceedToPayment = () => {
        const isBirthDateValid = validateBirthDate();
        const isIdCardValid = validateIdCard();

        if (isBirthDateValid && isIdCardValid) {
          router.push({ name: 'PaymentPage' });
        }
      };

      const validateIdCard = (): boolean => {
        // Clear previous error messages
        idCardError.value = false;
        idCardErrorMessage.value = '';

        Object.keys(mrz.value).forEach((key) => {
          mrz.value[key as keyof mrz] =
            mrz.value[key as keyof mrz].toUpperCase();
        });

        const documentParts: DocumentParts = {
          documentNumber: mrz.value.line1Field1.slice(0, 9),
          documentNumberCheck: mrz.value.line1Field1.slice(9, 10),
          birthDate: mrz.value.line2Field1.slice(0, 6),
          birthDateCheck: mrz.value.line2Field1.slice(6, 7),
          expiryDate: mrz.value.line2Field2.slice(0, 6),
          expiryDateCheck: mrz.value.line2Field2.slice(6, 7),
        };

        const isDocumentNumberValid = isDataWithCheckDigitValid(
          mrz.value.line1Field1,
        );
        const isBirthDateValid = isDataWithCheckDigitValid(
          mrz.value.line2Field1,
        );
        const isExpiryDateValid = isDataWithCheckDigitValid(
          mrz.value.line2Field2.slice(0, -1),
        );

        // Birth date comparison check
        const extractedBirthDate = documentParts.birthDate; // Format: YYMMDD
        const inputBirthDate = birthDate.value
          .split('.')
          .reverse()
          .join('')
          .slice(2, 8); // Convert DD.MM.YYYY to YYMMDD

        if (extractedBirthDate !== inputBirthDate) {
          idCardError.value = true;
          idCardErrorMessage.value =
            commonTexts.userFrontend.youngAdultSubscriptionForm.errorDateMatch;
          return false;
        }

        const finalString =
          documentParts.documentNumber +
          documentParts.documentNumberCheck +
          documentParts.birthDate +
          documentParts.birthDateCheck +
          documentParts.expiryDate +
          documentParts.expiryDateCheck +
          mrz.value.line2Field3;

        const isline2Field4Valid =
          calculateCheckDigit(finalString) === parseInt(mrz.value.line2Field4);

        const isValid =
          isDocumentNumberValid &&
          isBirthDateValid &&
          isExpiryDateValid &&
          isline2Field4Valid;

        console.log('Validation results:', { isValid, documentParts });

        if (isValid) {
          const info = extractIdInfo(documentParts);
          validationResult.value = { isValid: true, info };
          checkExpiry(info);
        } else {
          validationResult.value = { isValid: false };
          idCardError.value = true;
          idCardErrorMessage.value =
            commonTexts.userFrontend.youngAdultSubscriptionForm.errorIdCard;
        }

        return validationResult.value.isValid;
      };

      const extractIdInfo = (documentParts: DocumentParts) => {
        return {
          birthDate: {
            day: documentParts.birthDate.slice(4, 6),
            month: documentParts.birthDate.slice(2, 4),
            year: getFullYear(documentParts.birthDate.slice(0, 2)),
          },
          expiryDate: {
            day: documentParts.expiryDate.slice(4, 6),
            month: documentParts.expiryDate.slice(2, 4),
            year: `20${documentParts.expiryDate.slice(0, 2)}`,
          },
          age: calculateAge(documentParts.birthDate),
        };
      };

      return {
        getHeadline,
        getCopy,
        mrz,
        validationResult,
        isValidExpiry,
        birthDate,
        birthDateHasError,
        birthDateErrorMessage,
        birthDateErrorHintMessage,
        idCardError,
        idCardErrorMessage,
        validateAndProceedToPayment,
        formatDate,
        setBirthDate,
        setMrz,
        formatBirthDate,
        content,
        commonTexts,
      };
    },
  });
</script>

<style>
  :root {
    --document-number-full-width: 97px;
    --check-digit-width: 13px;
    --birth-date-full-width: 67px;
    --expiry-date-full-width: 75px;
    --serial-number-width: 40px;
  }
</style>

<style lang="scss" scoped>
  .error-info {
    background-color: transparent !important;
    text-align: left !important;
  }

  .fs-small {
    font-size: 0.75rem;
  }

  :deep(.color-red) {
    color: #ff4c4c;
  }

  .fw-bold,
  :deep(.fw-bold) {
    font-weight: bold;
  }

  .id-inputs {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .first-line,
    .second-line {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
    }

    .document-number-full {
      max-width: var(--document-number-full-width);
    }

    .birth-date-full {
      max-width: var(--birth-date-full-width);
    }

    .expiry-date-full {
      max-width: var(--expiry-date-full-width);
    }

    .serial-number {
      max-width: var(--serial-number-width);
    }

    .check-digit {
      max-width: var(--check-digit-width);
    }

    .arrow {
      user-select: none;
      white-space: nowrap;
    }

    :deep(.form-element-input-field--error) {
      &.document-number-full {
        max-width: calc(var(--document-number-full-width) + 30px);
      }

      &.birth-date-full {
        max-width: calc(var(--birth-date-full-width) + 30px);
      }

      &.expiry-date-full {
        max-width: calc(var(--expiry-date-full-width) + 30px);
      }

      &.serial-number {
        max-width: calc(var(--serial-number-width) + 30px);
      }

      &.check-digit {
        max-width: calc(var(--check-digit-width) + 30px);
      }
    }

    :deep(.form-element-input-field__input) {
      padding-bottom: 20px;
      padding-right: 0px;
      text-transform: uppercase;
    }

    :deep(.form-element-input-field--error input.form-element) {
      padding-right: 0px;
      background-position: right 10px bottom 19px;
    }

    :deep(
        .form-element-input-field:not(.form-element-input-field--not-empty)
          .form-element-input-field__label
      ) {
      top: 17px;
    }
  }
</style>
